import React, { Component } from "react";
import "./Articles.scss";
// import Header from "../Header/Header";
import PostListing from "../PostListing/PostListing";

class Articles extends Component {
  render() {
    return (
      <div className="articles">
        {/* <Header/> */}
        <main className="main">
          <h3 className="main__title">{this.props.title}</h3>
          <PostListing postEdges={this.props.postListing}></PostListing>
        </main>
      </div>
    );
  }
}

export default Articles;
