import React, { Component } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby"
import Layout from "../layout";
import Articles from "../components/Articles/Articles";
import config from "../../data/SiteConfig";

class ArticlesPage extends Component {
  
  render() {
    const postList = this.props.data.allMarkdownRemark.edges;
    const postListArticles = postList.filter( (item) => item.node.frontmatter.category === 'article');
    const postListNotes = postList.filter( (item) => item.node.frontmatter.category === 'note');
    return (
      <Layout>
        <div className="articles-container">
          <Helmet title={`Articles | ${config.siteTitle}`} />
          <Articles postListing={postListArticles} title="Articles" />
          <Articles postListing={postListNotes} title="Notes and Scribbles" />
        </div>
      </Layout>
    );
  }
}

export default ArticlesPage;


export const homeQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC },
      filter: {frontmatter: {category: {ne: "project"}}}
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            category
            credits {
              name
              role
            }
            cover {
              id
            }
            tags
          }
          id
          fields {
            slug
          }
        }
      }
    }
  }
`